<template>
  <div class="sign-up-situation">
    <tabs-tab defaultName="bmqk" :tabsList="tabsList" />
    <div class="filter-search-box">
      <div class="filter-cont">
        <van-field
          class="bmzt-field"
          v-model="textForm.applyStatusText"
          readonly
          clearable
          :right-icon="textForm.applyStatusText ? 'clear' : 'arrow-down'"
          placeholder="报名状态"
          @click="
            applyStatusOnClick(
              'applyStatus',
              'applyStatusIndex',
              applyStatusOptions,
              'applyStatusOnChange'
            )
          "
          @click-right-icon.stop="
            clearOnClick(
              'applyStatus',
              'applyStatusIndex',
              applyStatusOptions,
              'applyStatusOnChange'
            )
          "
        />
      </div>
      <div class="search-cont">
        <van-field
          class="jmxm-field"
          v-model="filterSearchForm.searchKey"
          clearable
          left-icon="search"
          placeholder="居民姓名"
          @input="searchKeyOnInput"
        />
      </div>
    </div>
    <div class="bmjm-list">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        :immediate-check="false"
        @load="getList"
      >
        <sig-up-sit-card
          v-for="(item, index) in sigSuplist"
          :key="'kcglkp' + index"
          :detail="item"
        />
      </van-list>
    </div>

    <van-popup
      v-model="selectPopupConfig.show"
      round
      position="bottom"
      safe-area-inset-bottom
      :key="selectPopupConfig.key"
    >
      <van-picker
        show-toolbar
        title="报名状态"
        :default-index="selectPopupConfig.defaultIndex"
        :columns="selectPopupConfig.options"
        @cancel="selectPopupConfig.show = false"
        @confirm="pickerOnConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import tabsTab from "../components/tabsTab.vue";
import sigUpSitCard from "../components/sigUpSitCard.vue";
import { setSignUpStatusOps } from "../map.js";
import { getCourseManageSignSitListUrl } from "../api.js";
import { debounce } from "lodash";
import axios from "axios";
import Vue from "vue";
import { List } from "vant";

Vue.use(List);

export default {
  name: "signUpSituation",
  components: { tabsTab, sigUpSitCard },
  data() {
    return {
      tabsList: [
        {
          title: "课程情况",
          name: "kcqk",
          routeName: "nightSchoolCourseSituation",
        },
        {
          title: "报名情况",
          name: "bmqk",
          routeName: "nightSchoolSignUpSituation",
        },
      ],
      applyStatusOptions: setSignUpStatusOps(),
      filterSearchForm: {
        applyStatus: void 0,
        searchKey: "",
      },
      textForm: {
        applyStatusText: "",
      },
      sigSuplist: [],
      selectPopupConfig: {
        show: false,
        key: "",
        formKey: "",
        indexKey: "",
        defaultIndex: 0,
        options: [],
        callBackKey: "",
      },
      total: 0,
      queryForm: {
        curPage: 1,
        pageSize: 10,
      },
      loading: false,
      finished: false,
      error: false,
      cancleAxiosSource: null,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      this.finished = false;
      const params = {
        courseId: this.$route.query.id,
        ...this.queryForm,
        ...this.filterSearchForm,
      };
      if (this.cancleAxiosSource) this.cancleAxiosSource.cancel("取消请求");
      this.cancleAxiosSource = axios.CancelToken.source();
      this.$axios
        .get(getCourseManageSignSitListUrl, {
          params,
          cancelToken: this.cancleAxiosSource.token,
        })
        .then((res) => {
          this.cancleAxiosSource = null;
          if (res && res.message === "取消请求") return;
          if (res && res.code === 200) {
            res.data = res.data || {};
            res.data.records = Array.isArray(res.data.records)
              ? res.data.records
              : [];
            this.sigSuplist.push(...res.data.records);
            this.queryForm.curPage++;
            this.total = res.data.total;
            this.loading = false;
            if (this.sigSuplist.length >= this.total) {
              this.finished = true;
            }
          } else {
            this.loading = false;
            this.error = true;
          }
        })
        .catch((err) => {
          this.cancleAxiosSource = null;
          this.loading = false;
          this.error = true;
        });
    },
    searchKeyOnInput: debounce(function (value) {
      this.queryForm.curPage = 1;
      this.sigSuplist = [];
      this.getList();
    }, 500),
    applyStatusOnClick(formKey, indexKey, options, callBackKey) {
      this.selectPopupConfig.formKey = formKey;
      this.selectPopupConfig.indexKey = indexKey;
      const defaultIndex = options.findIndex((item) =>
        Object.is(item.value, this.filterSearchForm[formKey])
      );
      this.selectPopupConfig.defaultIndex =
        defaultIndex >= 0 ? defaultIndex : 0;
      this.selectPopupConfig.options = options || [];
      this.selectPopupConfig.callBackKey = callBackKey || "";
      this.selectPopupConfig.key = new Date().getTime();
      this.$nextTick(() => {
        this.selectPopupConfig.show = true;
      });
    },
    clearOnClick(formKey, indexKey, options, callBackKey) {
      if (this.textForm.applyStatusText) {
        this.filterSearchForm.applyStatus = void 0;
        this.textForm.applyStatusText = "";
        this[callBackKey]({ text: "", value: "" }, -1);
      } else {
        this.applyStatusOnClick(formKey, indexKey, options, callBackKey);
      }
    },
    pickerOnConfirm(value, index) {
      if (
        Object.is(
          value.value,
          this.filterSearchForm[this.selectPopupConfig.formKey]
        )
      ) {
        this.selectPopupConfig.show = false;
      } else {
        this.filterSearchForm[this.selectPopupConfig.formKey] = value.value;
        this.textForm[this.selectPopupConfig.formKey + "Text"] = value.text;
        this.selectPopupConfig.defaultIndex = index;
        this.selectPopupConfig.show = false;
        this.selectPopupConfig.callBackKey &&
          this[this.selectPopupConfig.callBackKey](value, index);
      }
    },
    applyStatusOnChange(value, index) {
      this.queryForm.curPage = 1;
      this.sigSuplist = [];
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.sign-up-situation {
  box-sizing: border-box;
  height: calc(100%);
  height: calc(100% - constant(safe-area-inset-bottom));
  height: calc(100% - env(safe-area-inset-bottom));
  background-color: #fafafa;
  .filter-search-box {
    box-sizing: border-box;
    height: 92px;
    margin: 20px 30px 0px 30px;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
    border-radius: 16px;
    padding: 0px 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .filter-cont {
      box-sizing: border-box;
      flex-shrink: 0;
      width: 226px;
      flex-shrink: 0;
      text-align: center;
      padding-right: 5px;
      .bmzt-field {
        height: 66px;
        border-radius: 16px;
        padding-top: 0px;
        padding-bottom: 0px;
        line-height: 66px;
      }
    }
    .search-cont {
      flex-grow: 1;
      padding-left: 20px;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      .jmxm-field {
        background-color: #f7f7f7;
        height: 66px;
        border-radius: 16px;
        padding-top: 0px;
        padding-bottom: 0px;
        line-height: 66px;
      }
    }
  }
  .bmjm-list {
    box-sizing: border-box;
    height: calc(100% - 208px);
    overflow-x: hidden;
    overflow-y: auto;
    padding: 10px 30px 0px 30px;
    margin-top: 10px;
    .sig-up-sit-card {
      margin-bottom: 20px;
    }
  }
}
</style>
