<template>
  <div class="sig-up-sit-card" @click="cardOnClick">
    <div class="status-text" :class="courseOrderStatusClassMap[detail.applyStatus]">{{ courseOrderStatusMap[detail.applyStatus] }}</div>
    <div class="course-title">
      {{ detail.userName }}<span v-show="detail.mobileNum">(<phone-number-show :phoneNumber="detail.mobileNum" />)</span>
    </div>
    <div class="label-value-list">
      <div class="label-value-item">
        <div class="label-text">报名课程：</div>
        <div class="value-text">{{ detail.courseName }}</div>
      </div>
      <div class="label-value-item">
        <div class="label-text">报名时间：</div>
        <div class="value-text">{{ detail.applyTime }}</div>
      </div>
      <div class="label-value-item">
        <div class="label-text">报名方式：</div>
        <div class="value-text">线上报名</div>
      </div>
      <div class="label-value-item">
        <div class="label-text">支付金额：</div>
        <div class="value-text price-text">¥{{ detail.price }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { courseOrderStatusMap, courseOrderStatusClassMap } from '../map.js';
import phoneNumberShow from '@/components/bussiness/phoneNumberShow.vue';

export default {
  name: 'sigUpSitCard',
  components: {
    phoneNumberShow
  },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      courseOrderStatusMap,
      courseOrderStatusClassMap
    };
  },
  created() {},
  methods: {
    cardOnClick() {
      this.$router.push({
        name: 'nightSchoolSignUpDetail',
        query: { id: this.detail.id }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.sig-up-sit-card {
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
  border-radius: 16px;
  padding: 20px 30px;
  position: relative;
  .status-text {
    box-sizing: border-box;
    width: 160px;
    height: 44px;
    position: absolute;
    top: 0px;
    right: 0px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 16px 0px 44px;
    font-size: 24px;
    box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
    &.gray {
      background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
      color: #a8a8a8;
    }
    &.red {
      background: linear-gradient(180deg, #ed1d1d 0%, #ef6565 100%);
    }
    &.blue {
      background: linear-gradient(180deg, #73a8ff 0%, #2e7bff 100%);
    }
    &.green {
      background: linear-gradient(149deg, #43c93e 0%, #3ccc4d 100%);
    }
  }
  .course-title {
    box-sizing: border-box;
    font-size: 32px;
    line-height: 44px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.85);
    padding-right: 140px;
  }
  .label-value-list {
    box-sizing: border-box;
    .label-value-item {
      box-sizing: border-box;
      font-size: 24px;
      line-height: 34px;
      color: rgba(0, 0, 0, 0.85);
      margin-top: 10px;
      display: flex;
      align-items: flex-start;
      .label-text {
        box-sizing: border-box;
        flex-shrink: 0;
      }
      .value-text {
        box-sizing: border-box;
        &.price-text {
          color: #d9021b;
        }
      }
    }
  }
}
</style>
